<template>
  <v-navigation-drawer
    color="transparent"
    fixed
    height="auto"
    overlay-color="secondary"
    overlay-opacity=".8"
    temporary
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-list
      color="white"      
    >      
      <v-list-item
        v-for="item in items"        
        :key="item.name"
        :to="item.path ? { path: item.path, hash: item.hash } : { name: item.name }"
        :exact="item.name === 'Квартиры'"
        color="primary"
      >
        <v-list-item-content>
          <v-list-item-title v-text="item.name" />
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  export default {
    name: 'HomeDrawer',

    props: {
      items: {
        type: Array,
        default: () => ([]),
      },
    },
  }
</script>
